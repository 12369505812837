import React from "react"
import * as PropTypes from "prop-types"
import Helmet from "react-helmet"

import { Breadcrum, Type, SEO, Pagination, LastUpdated, TokenName, PostHeader, SocialLinks } from "../components/index"
import * as postStyles from '../components/posts/posts.module.scss'
import { postsType, tokenTypeType, pageLinkType } from '../types'
import { getDefaultPostData } from '../utils/structuredData'

class CommonTokensPageTemplate extends React.PureComponent {
  constructor(props) {
    super(props);
    const { 
      pageContext: {
        page,
        pages,
        post
      }
    } = props;
    const showIntro = page === 1;
    const { title, route } = post.node;
    this.breadcrumPages = [
      {
        text: 'Posts',
        to: '/posts/'
      },
      {
        text: title,
        to: route,
        active: showIntro
      }
    ];
    if(!showIntro) {
      this.breadcrumPages.push(      {
        text: `Page ${page} of ${pages}`,
        active: true,
        to: `${route}${page}/`
      })
    }
  }

  render () {
    const {
      pageContext: {
        next, prev,
        page, pages,
        nodes, total,
        post, limit
      }
    } = this.props;
    const { 
      title, author, date, shortDescription, route, imgSrc, theme, formattedDate,
      socialImgSrc: { childImageSharp: { fluid: { src: socialSrc } } }
     } = post.node;
    const showIntro = page === 1;
    const pageName = showIntro ? route : `${route}${page}/`;
    const keywords = ['common', 'printed', 'popular', 'most played'];
    return (
      <>
        <SEO
          title={showIntro ? title : `Page ${page} - ${title}`}
          description={shortDescription}
          keywords={keywords}
          pageUrl={pageName}
          imgSrc={socialSrc}
        />
        {
          showIntro && (
            <Helmet>
              <script type="application/ld+json">{getDefaultPostData({
                route: route, title: title, 
                shortDescription: shortDescription, date: date,
                keywords: keywords,
                img: socialSrc,
                modifiedDate: "2019-06-16"
              })}
              </script>
            </Helmet>
          )
        }
        <Breadcrum pages={this.breadcrumPages} />
        <article className={postStyles.article}>
          {
            showIntro && (<>
              <PostHeader
                imgSrc={imgSrc}
                title={title}
                author={author}
                date={formattedDate}
                theme={theme}
              />
              <SocialLinks title={title} />
              <div className={postStyles.body}>
                <p>Before we can reveal the most common Magic: the Gathering token cards, we need to determine what the definition of 'most common' is...</p>
                
                <p>It could be the token that is created by the most MTG cards? But, the results would favour token based mechanics, such as morph, manifest or energy counters. As these are mostly limited to single set, it wouldn't be a fair representation of tokens created throughout the history of magic.</p>

                <p>Perhaps, we could count the tokens that see the most play in all MTG formats? But, it would be hard to gather any accurate data, only a small number of games are documented or recorded. They are mostly competitive formats and wouldn't include casual games.</p>
                  
                <p>So, instead we count the tokens that have been printed the greatest number of times. These are tokens that are consistently created by MTG cards and will have been regularly played in the rotating formats.</p>

                <LastUpdated />
              </div>
              <h2>Top 20 list of the most common Magic: the Gathering Tokens:</h2>
            </>)
          }
          {
            nodes.map((token, index)=>{
              const count = showIntro ? (index + 1) : (index + 1) + ((page - 1) * limit);
              const { 
                name, color,
                power, toughness,
                types, tokens
              } = token.node;
              return (
                <ul key={count} className={postStyles.list}>
                  <li className={postStyles.countRow}>
                    <div className={postStyles.count}>
                      <small>#</small>
                      {`${count}`}
                    </div>
                    <p>
                      <TokenName power={power} toughness={toughness} color={color} types={types} name={name} />
                      {` has been printed `} 
                      <span className="big">{tokens.length}</span>
                      {` times`} 
                    </p>
                  </li>
                  <Type token={token.node} /> 
                </ul>
              )
            })
          }
        </article>
        <Pagination
          prev={prev} next={next}
          page={page} pages={pages}
          total={total} basePath={route}
          perPage={limit}
        />
      </>
    )
  }
}

CommonTokensPageTemplate.propTypes = {
  pageContext: PropTypes.shape({
    posts: postsType,
    nodes: PropTypes.arrayOf(PropTypes.shape({
      node: PropTypes.shape({
        ...tokenTypeType
      }).isRequired,
    })).isRequired,
    total: PropTypes.number.isRequired,
    ...pageLinkType,
  }).isRequired
}

export default CommonTokensPageTemplate
